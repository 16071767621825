/*
 * LISTS
 */

ul, ol {
    margin-bottom: 1em;
    margin-left: 1em;
}

ol {
    list-style-type: lower-latin;
}

li {

    a {
        color: $primary;
        text-decoration: underline;
        transition: color 300ms ease;
        cursor: pointer;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }

    ul, ol {
        margin-top: 0.5em;
        margin-left: 1em;
    }

    ol {
        list-style-type: lower-latin;
    }
}
