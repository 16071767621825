/*
 * BASE
 */

html {
    width: 100%;
    // height: auto;
    // min-height: 100%;
    line-height: 1.15;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    color: $base-color;
    @include font-smoothing(on);
}

body {
    width: 100%;
    // height: auto;
    // min-height: 100%;
    background-color: $white;
    font-size: 1.125rem;
    line-height: 1.5;
}

p, a, li, address {
    line-height: $base-line-height;

    @include interpolate(
        font-size,
        map-get($breakpoints, xs),
        map-get($breakpoints, xl),
        $base-font-size-min,
        $base-font-size-max
    );
}
