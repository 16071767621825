/*
 * HORIZONTAL RULE
 */

hr {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: $light;
    border-top-color: $light;
}