/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 40/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,    // 30rem
    sm: 640/16*1rem,    // 40 rem
    md: 960/16*1rem,    // 60 rem
    lg: 1024/16*1rem,   // 64 rem
    xl: 1140/16*1rem    // 71.25 rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,      // 0.5 rem
    sm: 16/16*1rem,     // 1.0 rem
    md: 24/16*1rem,     // 1.5 rem
    lg: 32/16*1rem,     // 2.0 rem
    xl: 40/16*1rem,     // 2.5 rem
    xxl: 64/16*1rem     // 4 rem
);

// COLORS

// Blues
$darker-blue: #4594C2;
$dark-blue: #4CA4D6;
$blue: #53B3EA;
$light-blue: #5AC3FF;
$ligher-blue: #EDF5FA;
// Greens
$darker-green: #44AD8E;
$dark-green: #4ABD9A;
$green: #52D0AA;
$light-green: #5AE6BC;
// Oranges
$darker-orange: #C28E45;
$dark-orange: #D69C4C;
$orange: #EAAB53;
$light-orange: #FFBF66;
// Reds
$darker-red: #B33F3F;
$dark-red: #CC4848;
$red: #EA5353;
$light-red: #FF6666;

$white: #ffffff;
$light: #F0EFF5;
$grey: #F0EFF5;
$medium: #4A4A4A;
$dark-midnight: #393D40;
$dark: #222526;
$black: #000000;

$primary: $blue;
$secondary: $green;
$tertiary: $orange;
$headingTextColor: #1A1A1A;
$textColor: rgba(0,0,0,.7);

$info: $blue;
$success: $green;
$warning: $orange;
$error: $red;

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;

// BORDER RADIUS
$border-radius: 4px;

// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

$min: .85;

$base-font-family: 'Roboto', Helvetica, sans-serif;
$base-font-size-min: 1rem * $min;
$base-font-size-max: 1.125rem;
$base-font-weight: 400;
$base-line-height: 1.8;
$base-color: $textColor;

$h1-font-family: $base-font-family;
$h1-font-size-min: 2.25rem * $min;
$h1-font-size-max: 2.25rem;
$h1-font-weight: 700;
$h1-line-height: 1.35;
$h1-color: $headingTextColor;

$h2-font-family: $base-font-family;
$h2-font-size-min: 1.825rem * $min;
$h2-font-size-max: 1.825rem;
$h2-font-weight: 700;
$h2-line-height: 1.35;
$h2-color: $headingTextColor;

$h3-font-family: $base-font-family;
$h3-font-size-min: 1.625rem * $min;
$h3-font-size-max: 1.635rem;
$h3-font-weight: 700;
$h3-line-height: 1.35;
$h3-color: $headingTextColor;

$h4-font-family: $base-font-family;
$h4-font-size-min: 1.425rem * $min;
$h4-font-size-max: 1.425rem;
$h4-font-weight: 500;
$h4-line-height: 1.35;
$h4-color: $headingTextColor;

$h5-font-family: $base-font-family;
$h5-font-size-min: 1.25rem * $min;
$h5-font-size-max: 1.25rem;
$h5-font-weight: 500;
$h5-line-height: 1.35;
$h5-color: $headingTextColor;

$h6-font-family: $base-font-family;
$h6-font-size-min: 1.15rem * $min;
$h6-font-size-max: 1.15rem;
$h6-font-weight: 500;
$h6-line-height: 1.35;
$h6-color: $headingTextColor;