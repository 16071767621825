/*
 * IMAGE BG
 */

.u-img-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 0;
    top: 0;
    left: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}