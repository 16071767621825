/*
 * MAIN
 */

#main.c-main {
    background-color: $white;
}

#google-maps {
    height: 500px;
}

.c-divider {
    display: block;
    background-color: $grey;
    width: 100%;
    height: 2px;
}