/*
 * SERVICES
 */

 $flow-spacing-column: 40px;
 $flow-spacing-row: 20px;
 $flow-border-radius: 8px;
 $dottedColor: #DAE1E6;

 .c-flow-header {
  background-color: $white;
  display: none;

  @include media-query(md) {
    display: grid;
    grid-template-columns: 1fr 1fr $flow-spacing-column $flow-spacing-column
                            1fr 1fr $flow-spacing-column $flow-spacing-column
                            1fr 1fr;
    grid-template-rows: auto
  }
 }

 .c-flow-content {

  @include media-query(md) {

    .c-flow-header-item {
      display: none;
    }
    display: grid;
    grid-template-columns: 1fr 1fr $flow-spacing-column $flow-spacing-column
                           1fr 1fr $flow-spacing-column $flow-spacing-column
                           1fr 1fr;
    grid-template-rows: auto
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr $flow-spacing-row
                        1fr 1fr;
  }
 }

.c-flow-header-item {
  background-color: $white;

  h3 {
    display: inline-flex;
    padding: .5rem 1rem;
    border: 1px solid $primary;
    color: $primary;
    font-size: 1.15rem;
    margin: 0 auto;
    border-radius: 16px;
    text-transform: uppercase;
  }

  .c-flow-header-img {
    display: block;
    position: relative;
    margin: 1.25rem auto;
    width: 150px;
    height: 150px;
    background-color: $grey;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }

    .c-icon {
      display: block;
      position: absolute;
      background-color: $primary;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 15px;
      right: 0;
      bottom: 0;
    }
  }
 }

 .c-flow-item {
  display: inline-flex;
  background-color: $ligher-blue;
  border-radius: 2rem;
  align-items: center;
  padding: .5rem;
  margin-bottom: 2rem;
  position: relative;
  p {
    margin-right: .5rem;
  }

  &:after {
    content: '';
    width: 2px;
    height: 2rem;
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    border-left: 2px dashed $dottedColor;
  }

  @include media-query(md) {
    display: flex;
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

 }

 .c-flow-item-first {

  &:after {
    display: none;;
  }
 }


 // Dotted lines

 .dotted-line {
   display: none;

   @include media-query(md) {
     display: block;
   }
 }

 .dotted-line-1 {
    grid-column: 2/5;
    grid-row: 3/5;
    border-radius: $flow-border-radius 0 0 0;
    border-top: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
 }

 .dotted-line-2 {
    grid-column: 2/5;
    grid-row: 7/9;
    border-radius: 0 0 0 $flow-border-radius ;
    border-bottom: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
 }

 .dotted-line-3 {
    border-left: 2px dashed $dottedColor;
    grid-column: 6/7;
    grid-row: 10/11;
 }

 .dotted-line-4 {
    border-left: 2px dashed $dottedColor;
    grid-column: 6/7;
    grid-row: 19/20;
 }

 .dotted-line-5 {
    grid-column: 6/10;
    grid-row: 21/23;
    border-radius: 0 $flow-border-radius 0 0;
    border-top: 2px dashed $dottedColor;
    border-right: 2px dashed $dottedColor;
 }

.dotted-line-6 {
    border-left: 2px dashed $dottedColor;
    grid-column: 10/11;
    grid-row: 25/26;
}

.dotted-line-7 {
    border-left: 2px dashed $dottedColor;
    grid-column: 10/11;
    grid-row: 28/29;
}

.dotted-line-8 {
    grid-column: 6/10;
    grid-row: 31/33;
    border-radius: 0 0 $flow-border-radius 0;
    border-right: 2px dashed $dottedColor;
    border-bottom: 2px dashed $dottedColor;
 }

.dotted-line-9 {
    grid-column: 2/5;
    grid-row: 33/35;
    border-radius: $flow-border-radius 0 0 0;
    border-top: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
}

.dotted-line-10 {
    grid-column: 2/5;
    grid-row: 37/39;
    border-radius: 0 0 0 $flow-border-radius;
    border-bottom: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
}

.dotted-line-11 {
    grid-column: 6/10;
    grid-row: 39/41;
    border-radius: 0 $flow-border-radius 0 0;
    border-top: 2px dashed $dottedColor;
    border-right: 2px dashed $dottedColor;
}

.dotted-line-12 {
    grid-column: 6/10;
    grid-row: 43/45;
    border-radius: 0 0 $flow-border-radius 0;
    border-right: 2px dashed $dottedColor;
    border-bottom: 2px dashed $dottedColor;
}

.dotted-line-13 {
    grid-column: 2/5;
    grid-row: 45/47;
    border-radius: $flow-border-radius 0 0 0;
    border-top: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
}

.dotted-line-14 {
    grid-column: 2/5;
    grid-row: 49/51;
    border-radius: 0 0 0 $flow-border-radius;
    border-bottom: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
}

.dotted-line-15 {
  border-left: 2px dashed $dottedColor;
  grid-column: 6/7;
  grid-row: 52/53;
}

.dotted-line-16 {
    grid-column: 2/5;
    grid-row: 54/56;
    border-radius: $flow-border-radius 0 0 0;
    border-top: 2px dashed $dottedColor;
    border-left: 2px dashed $dottedColor;
}

// Columns
.column-line {
  grid-row: 1/56;
  border-right: 1px solid $dottedColor;
}

.column-line-first {
  grid-column: 1/4;
}

.column-line-second {
  grid-column: 5/8;
}

.c-flow-client {
  grid-column: 1/3;
}

.c-flow-reception {
  grid-column: 5/7;
}

.c-flow-mechanics {
  grid-column: 9/11;
}

.c-flow-client,
.c-flow-reception,
.c-flow-mechanics {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  @include media-query(md) {
    display: block;
    margin: 0;
  }
}

// Rows
.c-flow-row-1 {
  grid-row: 2/4;
}

.c-flow-row-2 {
  grid-row: 5/7;
}

.c-flow-row-3 {
  grid-row: 8/10;
}

.c-flow-row-4 {
  grid-row: 11/13;
}

.c-flow-row-5 {
  grid-row: 14/16;
}

.c-flow-row-6 {
  grid-row: 17/19;
}

.c-flow-row-7 {
  grid-row: 20/22;
}

.c-flow-row-8 {
  grid-row: 23/25;
}

.c-flow-row-9 {
  grid-row: 26/28;
}

.c-flow-row-10 {
  grid-row: 29/31;
}

.c-flow-row-11 {
  grid-row: 32/34;
}

.c-flow-row-12 {
  grid-row: 35/37;
}

.c-flow-row-13 {
  grid-row: 38/40;
}

.c-flow-row-14 {
  grid-row: 41/43;
}

.c-flow-row-15 {
  grid-row: 44/46;
}

.c-flow-row-16 {
  grid-row: 47/49;
}

.c-flow-row-17 {
  grid-row: 50/52;
}

.c-flow-row-18 {
  grid-row: 53/55;
}

.c-flow-row-19 {
  grid-row: 56/58;
}