/*
 * NAV
 */

// Main Menu

 .c-nav-main {
    width: 100%;
    background-color: $dark;
    z-index: 99;
    position: fixed;
}

.c-nav-top-container {

    a {
        background-color: $primary;
        color: $white;
        font-weight: 500;
        width: 100%;
        transition: all 0.2s ease-in-out;

        .c-icon {
            left: 0;
            position: relative;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            background-color: $darker-blue;

            .c-icon {
                left: 5px;
            }
        }
    }
}

.c-nav-main-container {
    position: relative;

    @include media-query(md) {
        margin: auto;
        max-width: getBreakPoint(md);
        padding: 0 2.5rem;
    }

    @include media-query(lg) {
        margin: auto;
        max-width: getBreakPoint(lg);
        padding: 0 2.5rem;
    }

    @include media-query(xl) {
        margin: auto;
        max-width: getBreakPoint(xl);
        padding: 0 2.5rem;
    }
}

.c-nav-main-logo {
    position: relative;

    img {
        height: 44px;

        @include media-query(md) {
            height: 44px;
        }
    }
}

// https://codepen.io/stephaniewalter/pen/kficJ
.c-nav-menu-wrapper {
    z-index: 10;
    background: $dark;
    display: block;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    max-height: 100vh;
    overflow-y: scroll;

    @include media-query(md) {
        width: auto;
        position: relative;
        display: flex;
        top: 0;
        left: 0;
        overflow-y: visible;
        max-height: none !important;
    }

    .c-nav-menu {
        margin: 0;
        padding-left: 0;
        list-style: none;
        text-align: center;
        border-top: 1px solid $dark-midnight;

        @include media-query(md) {
            display: flex;
            justify-content: center;
            border-top: none;
        }
    }

    .c-nav-menu li {
        position: relative;
        margin: 0;
        padding: 0;
    }

    .c-nav-menu > li {
        padding: 0;
        margin: 0;

        &+li {
            border-top: 1px solid $dark-midnight;
        }

        @include media-query(md) {
            position: relative;
            display: block;
            padding: 0;

            &+li {
                border-top: none;
            }
        }
    }

    .c-nav-menu a {
        display: block;
        padding: .75em 0;
        margin: 0;
        border: none;
        color: $light;
        font-weight: 500;

        &:hover {
            background: $dark-midnight;
            color: $white;
        }

        @include media-query(md) {
            padding: 2em 1.25em;

            &:hover {
                background: $dark-midnight;
                color: $white;
            }
        }
    }

    /* the hidden link for RWD nav */
    a.menu-link {
        display: block;
        width: auto;
        padding-right: 15px;
        padding-left: 10px;
        border: none;
        margin: 0;

        &:hover {
            background: $darker-blue;
        }

        @include media-query(md) {
            display: none;
        }
    }

    /* the rest of the menu lvl 1 */
    a {
        display: block;
        position: relative;
        padding: 0;
        color: $light;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: $white;
            transition: all .5s;
        }
    }

    /* current links */
    .c-nav-menu > .c-nav-menu-item-active,
    .c-nav-menu > .c-nav-menu-item-activeancestor {
        position: relative;
        z-index: 12;

        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        @include media-query(md) {
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: $primary;
                position: absolute;
                bottom: 0;
            }
        }
    }

    /* submenu */
    .c-nav-menu-sub {
        display: block;
        background: none;
        margin-left: 0;
        box-sizing: border-box;
        position: absolute;
        max-height: 0;
        overflow: hidden;
        list-style: none outside none;
        text-align: left;
        text-transform: none;
        width: 100%;

        li {
            display: block;
            background: $dark-midnight;
            border-top: 1px solid $dark;
        }

        li:last-child {
            padding: 0;

            a {
                border: none;
            }
        }

        a {
            white-space: nowrap;
            padding-left: 1em;

            &:hover {
                background: $dark;
            }
        }

        @include media-query(md) {
            margin: 0;
            padding: 0;
            width: auto;
            box-shadow: 0 4px 24px 0 rgba(0,0,0,0.16), 0 8px 14px 0 rgba(0,0,0,0.5);

            a {
                padding: 1.25em;
            }
        }
    }

    /* unhide the 2nd level */
    .c-nav-menu .c-menu-item-has-sub {
        position: relative;
    }

    li .c-nav-menu-sub {
        margin-bottom: 0;
    }

    li .c-nav-menu-sub.active {
        max-height: 30em;
        overflow: visible;
        position: relative;
        z-index: 9;
        transition: max-height .5s ease-out;
    }

    li:hover .c-nav-menu-sub {

        @include media-query(md) {
            max-height: 30em;
            transition: max-height .5s;
        }
    }

    /* styling the toggle menu */
    .toggle-link{
        cursor: pointer;
        width: 74px;
        height: 40px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 200;

        &:after {
            content: '';
            border: solid $white;
            border-width: 0 5px 5px 0;
            display: inline-block;
            padding: 5px;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 50%;
            margin-right: -5px;
            transition: all .2s;
            transform: rotate(45deg);
        }

        @include media-query(md) {
            display: none;
        }
    }

    .toggle-link.active {

        &:after {
            transform: rotate(-135deg);
        }
    }
}

.c-nav-menu-wrapper nav[role=navigation] {
    overflow: hidden;
    max-height: 0;

    @include media-query(md) {
        overflow: visible;
        max-height: none !important;
    }
}

.c-nav-menu-wrapper nav[role=navigation].active {
    max-height: 100vh;
    transition: max-height .5s ease-out;
}

// Footer Menu

.c-nav-menu-footer {
    list-style-type: none;
    margin: 0;

    li {

        a {
            text-decoration: none;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.75rem;
        }
    }
}

// Breadcrumbs Menu

.c-nav-menu-breadcrumbs {
    list-style-type: none;
    margin: 0;

    li {
        display: inline;
        color: $primary;
        font-weight: 500;

        a {
            color: $primary;
            text-decoration: none;
            font-weight: 500;

            &:hover {
                color: $darker-blue;
                text-decoration: underline;
            };
        }
    }
}

// Pagination Menu

.c-pagination-item-link {
    text-decoration: none;
    font-weight: 500;
    background-color: $ligher-blue;
    border-radius: 4px;
    padding: .625em 1.125em;
    transition: background-color .1s ease;

    &:hover {
        background-color: $primary;
        text-decoration: none;
        color: $white;
    }
}

.c-pagination-item-link-active {
    background-color: $primary;
    color: $white;
}
