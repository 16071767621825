/*
 * FOOTER
 */

 .c-footer {
    background-color: $dark;

    p, address {
        color: $light;
    }

    p a {
        color: $primary;

        &:hover {
            color: $dark-blue;
        }
    }
 }

 .c-footer-top {

    h3 {
        color: $white;
    }
 }

.c-footer-logo {
    display: block;
    width: 100%;
}

.c-footer-nav-social {

    a {
        .c-icon {
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            .c-icon {
                fill: $primary;
            }
        }
    }
}
