/*
 * BREAKOUT
 */

.u-breakout {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
            }
        }
    }
}

.u-breakout-none {
    width: auto;
    position: static;
    left: auto;
    right: auto;
    margin-left: 0;
    margin-right: 0;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                width: auto;
                position: static;
                left: auto;
                right: auto;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}