/*
 * ICONS
 */

 .c-icon-logo-small {
  width: 54px;
  height: 64px;
}

.c-icon-line {
  width: 80px;
  height: 24px;
}
