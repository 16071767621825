/*
 * TEXT
 */

// Colors
.u-text-primary {
  color: $primary;
}

.u-text-dark {
  color: $dark;
}

.u-text-white {
  color: $white;
}