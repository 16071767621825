/*
 * CARDS
 */

 .c-card {
    border-radius: 8px;
    overflow: hidden;

    &:hover {

        .c-button.c-button-primary {
            background-color: $dark-blue;
        }

        .c-button.c-button-icon,
        .c-link-arrow {
            .c-icon {
                left: 5px;
            }
        }
    }
}

.c-card-text {
    border: none;
}

.c-card-figure-text {
    background-color: $white;
    border: none;

    img {
        opacity: 1;
    }
}

.c-card-primary {
    background-color: $primary;
}

.c-card-dark {
    background-color: $dark;
}

.c-card-service {
    box-shadow: none;
    cursor: pointer;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }

    &:hover {
        box-shadow: 0 12px 36px rgba(black, .1);
    }
}

.c-card-cta {
    cursor: pointer;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }
}

.c-card-link {
    height: inherit;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }

    &:hover {
        box-shadow: 0 12px 36px rgba(black, .1);
    }
}