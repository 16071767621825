/*
 * TABS
 */

.c-tabs-nav {
    background-color: $light;

    ul {
        align-content: start;
        margin: 0;

        li {
            display: block;

            a {
                display: block;
                color: $dark
            }

            &:before {
                display: none;
            }

            &:hover {
                background-color: $primary;
                transition: background-color 175ms linear;

                a {
                    color: $white;
                    transition: color 175ms linear;
                    text-decoration: none;
                }
            }
        }

        li.is-active {
            background-color: $primary;

            a {
                color: $white;
            }
        }
    }
}

.c-tabs-details {

    img {
        width: auto;
    }

    p {
        margin: 0 !important;
    }
}
