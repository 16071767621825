/*
 * BUTTONS
 */

 .c-button {
    text-transform: none;
    outline: none;
 }

.c-button-primary {

   &:hover {
     background-color: $dark-blue;
   }
 }

.c-button.c-button-white {
   background-color: $white;
   color: $primary;
 }

.c-button.c-button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .c-icon {
    left: 0;
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  &:hover {

    .c-icon {
      left: 5px;
    }
  }
 }

 // Links

 .c-link {

  span {
    font-weight: 500;
    color: $white;
  }
 }

 .c-link.c-link-primary {

  span {
    color: $primary;
  }

  .c-icon {
    fill: $primary;
  }
 }

 .c-link.c-link-arrow,
 .c-link.c-link-arrow-down,
 .c-link.c-link-arrow-up {
  align-items: center;
  outline: none;

  .c-icon {
    position: relative;
    transition: all 0.2s ease-in-out;
  }
 }

.c-link.c-link-arrow {

  .c-icon {
    left: 0;
  }

  &:hover {

    .c-icon {
      left: 5px;
    }
  }
}

.c-link.c-link-arrow-down,
.c-link.c-link-arrow-up {

  .c-icon {
    top: 0;
  }
}

.c-link.c-link-arrow-down {

  &:hover {

    .c-icon {
      top: 5px;
    }
  }
}

.c-link.c-link-arrow-up {

  &:hover {

    .c-icon {
      top: -5px;
    }
  }
}

.c-next-page-link {

  @include media-query(md) {
    font-size: 2rem;

    .c-icon {
      width: 35px;
      height: 35px;
    }
  }
}