/*
 * GRID
 */

// GRID
.o-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin: 0 #{-$gutter/2};
}

// COLUMN
.o-col {
    padding: #{$gutter/2} #{$gutter/2};

    @for $i from 1 through $columns {
        &-#{$i} {
            width: 100/$columns*$i*1%;
        }
    }

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            @for $i from 1 through $columns {
                &-#{$i}-#{$bp} {
                    width: 100/$columns*$i*1%;
                }
            }
        }
    }
}

.o-offset {
    @for $i from 1 through $columns {
        &-#{$i} {
            margin-left: 100/$columns*$i*1%;
        }
    }

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            @for $i from 1 through $columns {
                &-#{$i}-#{$bp} {
                    margin-left: 100/$columns*$i*1%;
                }
            }
        }
    }
}
