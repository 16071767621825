/*
 * App
 */

#app {
    background-color: $white;
}

.inverted {

  h1, h2, h3, h4, h5, h6 {
      color: $white;
  }

  p, li, a, address {
    color: rgba(255,255,255,.7);
  }
}

body.home {

    section.c-block {
        background-color: $ligher-blue;
    }
}

.c-image-border-radius {
    border-radius: 8px;
}
