/*
 * HEADER
 */

 .c-header {
    position: relative;

    .o-container {
      position: relative;
      z-index: 1;
   }

   .u-image-bg {
      z-index: 0;
   }
 }

 .c-header-basic,
 .c-header-form,
 .c-header-home {
   margin-top: 75px;

   @include media-query(md) {
      margin-top: 100px;
   }
 }

 .c-header-basic {
   background-color: $ligher-blue;

   h1, h2, h3 {
      color: $primary;
   }

   p {

      a {
         color: $primary;
         text-decoration: none !important;

         &:hover {
            text-decoration: underline !important;
         }
      }
   }

   .u-img-bg {
      background-color: rgb(34,37,38);

      &:after {
         content: '';
         position: absolute;
         left: 0;
         top: 0;
         display: block;
         width: 100%;
         height: 100%;
         z-index: 1;
         background: rgb(34,37,38);
         background: linear-gradient(90deg, rgba(34,37,38,1) 12%,rgba(80,86,89,.666) 52%, rgba(255,255,255,0) 75%);
      }
   }
 }

 .c-header-basic.inverted {

   h1, h2, h3 {
      color: $white;
   }

 }

 .c-header-basic
 .c-header-basic.inverted,
 .c-header-form {

   p {

      a {
         color: $primary !important;
         text-decoration: none !important;

         &:hover {
            text-decoration: underline !important;
         }
      }
   }
 }

 .c-header-form {
    background-color: $dark;
 }

 .c-header-mockup {
   position: relative;

   .o-container{
      position: relative;
      z-index: 2;
   }

   &:before {
      content: '';
      width: 100%;
      height: 50%;
      display: block;
      background-color: $ligher-blue;
      position: absolute;
      bottom: 0;
   }
 }

.c-header-home.c-header-home-has-cta-btn {
   margin-top: 156px;

   @include media-query(xs) {
      margin-top: 133px;
   }

   @include media-query(md) {
      margin-top: 160px;
   }
 }
