/*
 * PARAGRAPHS
 */

 p {

   small {
    line-height: 1.3;
   }
 }
