/*
 * CARDS
 */

 .c-card {
    position: relative;
    background: $white;
    border-radius: $border-radius;
    transition: all .15s ease;
    height: 100%;
    box-shadow: 0 6px 12px rgba(black, .1);
}

.c-card-grid {

    > .o-col {
        margin-bottom: $gutter;
    }
}

.c-card-text {
    border: 1px solid $primary;
}

.c-card-figure {
    position: relative;
    overflow: hidden;
    background-color: $dark;

    figcaption {
        position: absolute;
        display: block;
        bottom: 0;
        width: 100%;
        height: 100%;

        .u-text-container {
            width: 100%;
            height: 100%;

            h1, h2, h3, h4, h5, h6, p {
                color: $white;
            }
        }
    }
}

.c-card-figure-text {
    background-color: $dark;
    border: 1px solid $primary;

    figcaption {
        background-color: $white;
    }
}

.c-card-text,
.c-card-figure,
.c-card-figure-text {

    cursor: pointer;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }

    img {
        opacity: .8;
        transition: opacity .15s ease;
    }

    &:hover {
        box-shadow: 0 12px 36px rgba(black, .1);

        img {
            opacity: 1;
        }

        .c-link-chevron::after {
            left: 1em;
        }
    }
}
