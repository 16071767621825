/**
 * Defaults
 */
.mCCF {
    font-size: initial;
    margin: 0;
    padding: .7em 1em;
    position: fixed;
    z-index: 9999;

    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

}
.mCCF--top,
.mCCF--bottom {
    right: 0;
    left: 0;
}
.mCCF--top {
    top: 0;
}
.mCCF--bottom {
    bottom: 0;
}
.mCCF__message {
    font-size: 90%;
    letter-spacing: .03em;
    line-height: 2;
    margin: 0;
}
.mCCF__link {
    border: none;
    text-decoration: none;
    margin-left: .5em;
}
.mCCF__accept {
    border-radius: 4px;
    border: none;
    cursor: pointer;
    float: right;
    font-size: .9em;
    margin-left: 1em;
    padding: .4em .6em;
}

/**
 * Dark
 */

.mCCF--dark {
    background: #4e4d4d;
}
.mCCF--dark.mCCF--top {
    box-shadow: 0 1px 8px #363535;
}
.mCCF--dark.mCCF--bottom {
    box-shadow: 0 -1px 4px #363535;
}
.mCCF--dark .mCCF__message {
    color: #fff;
}
.mCCF--dark .mCCF__link {
    color: white;
    text-decoration: underline;
}
.mCCF--dark .mCCF__accept {
    background: $primary;
    color: white;
    font-size: .8em;
    padding: 1em 2em;
    border-radius: 4px;
    transition: all .4s ease;
    letter-spacing: 1.5px;
    font-weight: bold;
    text-transform: uppercase;
}
.mCCF--dark .mCCF__accept:hover {
    background-color: darken($primary, 5%);
    color: white;
}

/**
 * Light
 */
.mCCF--light {
    background: #e4e4e4;
    color: #000;
}
.mCCF--light.mCCF--top {
    box-shadow: 0 0 8px #9a9a9a;
}
.mCCF--light.mCCF--bottom {
    box-shadow: 1px -1px 3px #9a9a9a;
}
.mCCF--light .mCCF__message {
    color: #000;
}
.mCCF--light .mCCF__link {
    color: #6f6e6e;
}
.mCCF--light .mCCF__link:hover {
    color: #7a7a7c;
}
.mCCF--light .mCCF__accept {
    color: #fff;
    background: #929292;
}
.mCCF--light .mCCF__accept:hover {
    background: #626263;
}
