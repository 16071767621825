/*
 * SECTIONS
 */

 .o-section-white {
  background-color: $white;
}

.o-section-light {
  background-color: $ligher-blue;
}

.o-section-dark {
  background-color: $dark;
}

.o-section-shadow {

  .o-grid {
    margin: 0 .5rem;
    box-shadow: 0 6px 12px rgba(black, .1);
    background-color: $white;
    border-radius: 8px;
  }

}
