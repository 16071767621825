/*
 * SIDEPUSHMENU
 */

// https://tympanus.net/Blueprints/SlidePushMenus/

.c-menu-push {
    background: $primary;
    position: fixed;
    overflow-y: scroll;
    transition: all 0.3s ease;

    a {
        display: block;
        color: $white;
        font-size: 1.1em;
    }
}

.c-menu-push-vertical {
    width: 240px;
    height: 100%;
    top: 0;
    z-index: 1000;

    a {
        border-bottom: 1px solid $primary;
        padding: 1em;
    }
}

.c-menu-push-right {
    right: -240px;
}

.c-menu-push-right.c-menu-push-open {
    right: 0;
}

body.c-menu-push-body {
    overflow-x: hidden;
    position: relative;
    left: 0;
    transition: all 0.3s ease;
}

body.c-menu-push-body-toleft {
    left: -240px;
}
