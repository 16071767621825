/*
* FORMS
*/

.c-form {
  .o-col {
    @include media-query(md) {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}

.c-form-group {
  p {
    line-height: 1.3;
  }
}

.c-form-input-text,
.c-form-input-password,
.c-form-input-textarea {
  border-color: $grey;
  background-color: $white;
  border-radius: 8px;

  &:focus {
    border-color: $primary;
  }
}

.vfl-label {
  top: 1em !important;
  left: 19px !important;
}

.vfl-label-on-input {
  top: .3em !important;
  left: 19px !important;
}

.vfl-label-on-focus {
  color: $primary !important;
}

.inverted {
  .c-form-input-text,
  .c-form-input-password,
  .c-form-input-textarea {
    border-color: $medium;
    color: rgba(255,255,255,.7);
    background-color: rgba(255,255,255,.08);

    &:focus {
      border-color: $primary;
    }
  }
}


